<template>
  <a
    class="frn_button"
    :class="{ light: light }"
    v-bind:href="href"
    v-bind:target="target"
    >{{ text }}</a
  >
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    text: String,
    href: String,
    target: String,
    light: String,
  },

  computed: {},
  methods: {},
};
</script>
<style scoped lang="scss">
@import "../../scss/colors";

#frn-loginbox {
  a {
    text-decoration: none;
    font-size: 1em;
    color: $frn-white;
  }
  .frn_button {
    text-transform: uppercase;
    font-size: 1rem;
    text-align: center;
    border: none;
    font-weight: 700;
    background: $frn-dark-blue;
    padding: 6px 13px;
    display: inline-block;
    &:hover {
      background: $frn-dark-blue-hover;
    }
  }
  .light {
    background: #828999;
  }
  .light:hover {
    background: #4e5a75;
  }
  .frn_button:link,
  .frn_button:visited,
  .frn_button:link,
  .frn_button:visited {
    color: $frn-white;
    text-decoration: none;
  }

  .frn_subPage {
    display: flex;
    justify-content: flex-end;
    padding: 0 6px 0;

    .frn_button {
      font-size: 0.8rem;
      padding: 3px 9px;
      white-space: nowrap;
      background-color: $frn-green;
      &:last-of-type {
        margin-left: 5px;
        background-color: $frn-dark-blue;
      }
    }
  }
}
</style>
