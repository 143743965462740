<template>
  <div
    id="frn-loginbox"
    class="root"
    :class="[frnportalnoindex ? 'frn_subPage' : '']"
  >
    <div
      class="loginbox"
      :class="[$mq, boxColor, isLoggedIn ? 'isLoggedIn' : '']"
    >
      <Error
        v-if="errorMessage"
        v-bind:theme="theme"
        v-bind:world="world"
        v-bind:tenent="tenent"
        :message="errorMessage"
        @errorMessage="updateErrorMessage"
      ></Error>
      <Maintenance
        v-else-if="maintenance"
        :maintenanceMessage="maintenance"
        v-bind:theme="theme"
        v-bind:world="world"
        v-bind:tenent="tenent"
      ></Maintenance>
      <Info
        v-else-if="isLoggedIn === true"
        v-bind:theme="theme"
        v-bind:world="world"
        v-bind:tenent="tenent"
        v-bind:frnportalnoindex="frnportalnoindex"
      />
      <Form
        v-else
        v-bind:world="world"
        v-bind:theme="theme"
        v-bind:tenent="tenent"
        v-bind:cloudguestlogin="cloudguestlogin"
        v-bind:callbackurl="getCallback"
        v-bind:ignorelogoutforget="ignorelogoutforget"
        v-bind:frnportalnoindex="frnportalnoindex"
        v-bind:domainlogin="domainlogin"
        @errorMessage="updateErrorMessage"
        :loginname.sync="propLoginname"
        :password.sync="propPassWord"
      />
    </div>
  </div>
</template>
<script>
import Info from "./Info";
import Form from "./Form";
import Error from "./Error";
import Maintenance from "./Maintenance";
import qs from "querystring";
import FrnApiClient from "@frnde/frn-api-client";
import Vue from "vue";

import VueMq from "vue-mq";

Vue.use(VueMq, {
  breakpoints: {
    mobile: 768,
    tablet: 1279,
    desktop: Infinity,
  },
});

export default {
  plugins: {},
  components: {
    Info,
    Form,
    Error,
    Maintenance,
  },
  data() {
    return {
      errorMessage: null,
      maintenance: null,
      callback: null,
      password: "",
      propLoginname: this.loginname,
      propPassWord: this.password,
      isLoggedIn: null,
    };
  },
  created() {
    const queryParameter = qs.parse(location.search.replace(/\?/, ""));

    switch (true) {
      case /stage-oauth.freenet.de/.test(location.host):
      case /stage-power.freenet.de/.test(location.host):
      case /stage-devk.freenet.de/.test(location.host):
      case /stage-cloud.md.de/.test(location.host):
      case /stage-webmail.freenet.de/.test(location.host):
      case /stage-plus.freenet.de/.test(location.host):
      case /stage-components.freenet.de/.test(location.host):
      case /stage-registrierung.freenet.de/.test(location.host):
      case /stage.freenetcloud.de/.test(location.host):
        FrnApiClient.Configuration.set("staging");
        this.callback = "https://stage-webmail.freenet.de";
        break;

      case /test-oauth.freenet.de/.test(location.host):
      case /test-power.freenet.de/.test(location.host):
      case /test-devk.freenet.de/.test(location.host):
      case /test-cloud.md.de/.test(location.host):
      case /test-webmail.freenet.de/.test(location.host):
      case /test-plus.freenet.de/.test(location.host):
      case /test-components.freenet.de/.test(location.host):
      case /test-registrierung.freenet.de/.test(location.host):
      case /test.freenetcloud.de/.test(location.host):
        FrnApiClient.Configuration.set("testing");
        this.callback = "https://test-webmail.freenet.de";
        break;
      case "localhost:8080":
      case "local.freenet.de":
      case "local.freenet.de:8080":
      case "local.freenet.de:8443":
      case /.*-oauth.freenet.de/.test(location.host):
      case /.*-power.freenet.de/.test(location.host):
      case /.*-devk.freenet.de/.test(location.host):
      case /.*-cloud.md.de/.test(location.host):
      case /.*-webmail.freenet.de/.test(location.host):
      case /.*-plus.freenet.de/.test(location.host):
      case /.*-components.freenet.de/.test(location.host):
      case /.*-registrierung.freenet.de/.test(location.host):
      case /.*.test.freenetcloud.de/.test(location.host):
        FrnApiClient.Configuration.set("testing");
        this.callback = "https://test-webmail.freenet.de/web";
        break;
      default:
        FrnApiClient.Configuration.set("production");
        this.callback = "https://webmail.freenet.de";
        break;
    }
    // override callback from querystring
    if (queryParameter && queryParameter.callback) {
      this.callback = queryParameter.callback;
    }
    this.checkMaintenance();

    this.checkLogin();
  },
  props: {
    // the world for auth.freenet.de
    world: {
      type: String,
      default: "2",
    },
    /**
     * callback url for action after the login
     * optional
     */
    callbackurl: String,
    /**
     * if true ignore the redirect to the logout forget page
     */
    ignorelogoutforget: String,

    /**
     * for cloud guest login hide reg and password change
     * and rename Login to Zugangscode
     */
    cloudguestlogin: {
      default: "false",
      type: String,
    },
    /**
     * prop to the style the login box
     *
     * default theme is frn-green for freenet
     * other theme is frn-white for devk and md
     *
     */
    theme: {
      default: "frn-green",
      type: String,
    },

    /**
     * loginname for prefill the loginname field
     */
    loginname: String,

    /**
     * loginname for prefill the tenent field
     */
    tenent: String,

    /**
     * show small info page for freenet portal sub pages
     */
    frnportalnoindex: {
      default: false,
      type: Boolean,
    },
    /**
     * make a domain login against oauth
     */
    domainlogin: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    getCallback() {
      if (this.callbackurl) {
        return this.callbackurl;
      } else {
        return this.callback;
      }
    },
    boxColor() {
      let className = "green";
      switch (this.theme) {
        case "frn-white":
          className = "white";
          break;
      }
      return className;
    },
  },
  methods: {
    updateErrorMessage(message) {
      this.errorMessage = message;
    },
    checkMaintenance() {
      FrnApiClient.Maintenance().then((response) => {
        this.maintenance = response && response.message ? response : null;
        if (this.maintenance) {
          window.setTimeout(this.checkMaintenance, response.retry_after * 1000);
        }
      });
    },
    async checkLogin() {
      FrnApiClient.Auth.isLoggedIn().then(() => {
        this.isLoggedIn = true;
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../scss/colors";
.root {
  font: normal 11px "Open Sans", Arial, Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.loginbox {
  text-align: left;
  .frn-white {
    .message a {
      color: $black-text !important;
    }
  }
}
#frn-loginbox {
  .loginbox {
    text-align: left;
    max-width: 304px;
    height: auto;
    &.mobile {
      width: 100% !important;
      max-width: none;
    }
  }
  .green {
    background-color: $frn-green;
  }

  .white {
    background-color: $frn-white;
  }
  .hidden {
    display: none;
  }

  &.frn_subPage {
    .isLoggedIn {
      height: auto !important;
    }
  }
}
</style>
